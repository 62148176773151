import React from 'react';
import Andorra from './AD.svg';
import Afghanistan from './AF.svg';
import AntiguaAndBarbuda from './AG.svg';
import Anguilla from './AI.svg';
import Albania from './AL.svg';
import Armenia from './AM.svg';
import Angola from './AO.svg';
import Antarctica from './AQ.svg';
import Argentina from './AR.svg';
import AmericanSamoa from './AS.svg';
import Austria from './AT.svg';
import Australia from './AU.svg';
import Aruba from './AW.svg';
import ÅlandIslands from './AX.svg';
import Azerbaijan from './AZ.svg';
import Bosnia from './BA.svg';
import Barbados from './BB.svg';
import Bangladesh from './BD.svg';
import Belgium from './BE.svg';
import BurkinaFaso from './BF.svg';
import Bulgaria from './BG.svg';
import Bahrain from './BH.svg';
import Burundi from './BI.svg';
import Benin from './BJ.svg';
import Bermuda from './BM.svg';
import Brunei from './BN.svg';
import Bolivia from './BO.svg';
import Bonaire from './BQ.svg';
import Brazil from './BR.svg';
import Bahamas from './BS.svg';
import Bhutan from './BT.svg';
import BouvetIsland from './BV.svg';
import Botswana from './BW.svg';
import Belarus from './BY.svg';
import Belize from './BZ.svg';
import Canada from './CA.svg';
import CocosIslands from './CC.svg';
import CongoDemocraticRepublic from './CD.svg';
import CentralAfricanRepublic from './CF.svg';
import Congo from './CG.svg';
import { default as CôtedIvoire, default as IvoryCoast } from './CI.svg';
import CookIslands from './CK.svg';
import Chile from './CL.svg';
import Cameroon from './CM.svg';
import China from './CN.svg';
import Colombia from './CO.svg';
import CostaRica from './CR.svg';
import Cuba from './CU.svg';
import CapeVerde from './CV.svg';
import Curaçao from './CW.svg';
import ChristmasIsland from './CX.svg';
import Cyprus from './CY.svg';
import CzechRepublic from './CZ.svg';
import Germany from './DE.svg';
import Djibouti from './DJ.svg';
import Denmark from './DK.svg';
import Dominica from './DM.svg';
import DominicanRepublic from './DO.svg';
import Algeria from './DZ.svg';
import Ecuador from './EC.svg';
import Estonia from './EE.svg';
import Egypt from './EG.svg';
import Eritrea from './ER.svg';
import Ethiopia from './ET.svg';
import Finland from './FI.svg';
import Fiji from './FJ.svg';
import FalklandIslands from './FK.svg';
import Micronesia from './FM.svg';
import FaroeIslands from './FO.svg';
import France from './FR.svg';
import Gabon from './GA.svg';
import Grenada from './GD.svg';
import Georgia from './GE.svg';
import FrenchGuiana from './GF.svg';
import Guernsey from './GG.svg';
import Ghana from './GH.svg';
import Gibraltar from './GI.svg';
import Greenland from './GL.svg';
import Gambia from './GM.svg';
import Guinea from './GN.svg';
import Guadeloupe from './GP.svg';
import EquatorialGuinea from './GQ.svg';
import Greece from './GR.svg';
import Guatemala from './GT.svg';
import Guam from './GU.svg';
import GuineaBissau from './GW.svg';
import Guyana from './GY.svg';
import HongKong from './HK.svg';
import HeardIsland from './HM.svg';
import Honduras from './HN.svg';
import Croatia from './HR.svg';
import Haiti from './HT.svg';
import Hungary from './HU.svg';
import Indonesia from './ID.svg';
import Ireland from './IE.svg';
import Israel from './IL.svg';
import IsleOfMan from './IM.svg';
import India from './IN.svg';
import BritishIndianOceanTerritory from './IO.svg';
import Iraq from './IQ.svg';
import Iran from './IR.svg';
import Iceland from './IS.svg';
import Italy from './IT.svg';
import Jersey from './JE.svg';
import Jamaica from './JM.svg';
import Jordan from './JO.svg';
import Japan from './JP.svg';
import Kenya from './KE.svg';
import Kyrgyzstan from './KG.svg';
import Cambodia from './KH.svg';
import Kiribati from './KI.svg';
import Comoros from './KM.svg';
import KoreaDemocraticRepublic from './KP.svg';
import KoreaRepublic from './KR.svg';
import Kuwait from './KW.svg';
import CaymanIslands from './KY.svg';
import Kazakhstan from './KZ.svg';
import Lao from './LA.svg';
import Lebanon from './LB.svg';
import Liechtenstein from './LI.svg';
import Liberia from './LR.svg';
import Lesotho from './LS.svg';
import Lithuania from './LT.svg';
import Luxembourg from './LU.svg';
import Latvia from './LV.svg';
import Libya from './LY.svg';
import Morocco from './MA.svg';
import { default as Macedonia, default as Monaco } from './MC.svg';
import Moldova from './MD.svg';
import Montenegro from './ME.svg';
import Madagascar from './MG.svg';
import MarshallIslands from './MH.svg';
import Mali from './ML.svg';
import { default as Burma, default as Myanmar } from './MM.svg';
import Mongolia from './MN.svg';
import Macao from './MO.svg';
import Martinique from './MQ.svg';
import Mauritania from './MR.svg';
import Montserrat from './MS.svg';
import Malta from './MT.svg';
import Mauritius from './MU.svg';
import Maldives from './MV.svg';
import Malawi from './MW.svg';
import Mexico from './MX.svg';
import Malaysia from './MY.svg';
import Mozambique from './MZ.svg';
import Namibia from './NA.svg';
import Nepal from './NP.svg';
import Nauru from './NR.svg';
import FrenchPolynesia from './PF.svg';
import ElSalvador from './SV.svg';
import Chad from './TD.svg';
import FrenchSouthernTerritories from './TF.svg';
import HolySee from './VA.svg';
import Kosovo from './XK.svg';
import Mayotte from './YT.svg';
// import  NetherlandsAntilles  from './AN.svg';
import { Image } from '@chakra-ui/react';
import UAE from './AE.svg';
import SaintBarthélemy from './BL.svg';
import Switzerland from './CH.svg';
import WesternSahara from './EH.svg';
import Spain from './ES.svg';
import UnitedKingdom from './GB.svg';
import SouthGeorgia from './GS.svg';
import SaintKitts from './KN.svg';
import SouthKorea from './KR.svg';
import SaintLucia from './LC.svg';
import SriLanka from './LK.svg';
import SaintMartin from './MF.svg';
import Mariana from './MP.svg';
import NewCaledonia from './NC.svg';
import Niger from './NE.svg';
import Norfolk from './NF.svg';
import Nigeria from './NG.svg';
import Nicaragua from './NI.svg';
import Netherlands from './NL.svg';
import Norway from './NO.svg';
import Niue from './NU.svg';
import NewZealand from './NZ.svg';
import Oman from './OM.svg';
import Panama from './PA.svg';
import Peru from './PE.svg';
import Papua from './PG.svg';
import Philippines from './PH.svg';
import Pakistan from './PK.svg';
import Poland from './PL.svg';
import SaintPierre from './PM.svg';
import Pitcairn from './PN.svg';
import PuertoRico from './PR.svg';
import Palestine from './PS.svg';
import Portugal from './PT.svg';
import Palau from './PW.svg';
import Paraguay from './PY.svg';
import Qatar from './QA.svg';
import Réunion from './RE.svg';
import Romania from './RO.svg';
import Serbia from './RS.svg';
import Russia from './RU.svg';
import Rwanda from './RW.svg';
import SaudiArabia from './SA.svg';
import SolomonIslands from './SB.svg';
import Seychelles from './SC.svg';
import Sudan from './SD.svg';
import Sweden from './SE.svg';
import Singapore from './SG.svg';
import SaintHelena from './SH.svg';
import Slovenia from './SI.svg';
import Svalbard from './SJ.svg';
import Slovakia from './SK.svg';
import SierraLeone from './SL.svg';
import SanMarino from './SM.svg';
import Senegal from './SN.svg';
import Somalia from './SO.svg';
import Suriname from './SR.svg';
import SouthSudan from './SS.svg';
import SaoTome from './ST.svg';
import SintMaarten from './SX.svg';
import Syria from './SY.svg';
import Swaziland from './SZ.svg';
import Turks from './TC.svg';
import Togo from './TG.svg';
import Thailand from './TH.svg';
import Tajikistan from './TJ.svg';
import Tokelau from './TK.svg';
import Timor from './TL.svg';
import Turkmenistan from './TM.svg';
import Tunisia from './TN.svg';
import Tonga from './TO.svg';
import Turkey from './TR.svg';
import Trinidad from './TT.svg';
import Tuvalu from './TV.svg';
import Taiwan from './TW.svg';
import Tanzania from './TZ.svg';
import Ukraine from './UA.svg';
import Uganda from './UG.svg';
import USMOI from './UM.svg';
import USA from './US.svg';
import Uruguay from './UY.svg';
import Uzbekistan from './UZ.svg';
import { default as SaintVincent, default as Vincent } from './VC.svg';
import Venezuela from './VE.svg';
import VIB from './VG.svg';
import VIU from './VI.svg';
import Vietnam from './VN.svg';
import Vanuatu from './VU.svg';
import Wallis from './WF.svg';
import Samoa from './WS.svg';
import Yemen from './YE.svg';
import SouthAfrica from './ZA.svg';
import Zambia from './ZM.svg';
import Zimbabwe from './ZW.svg';

function CountryFlag({ countryName }: { countryName: string }) {
    const flagMap = {
        "Afghanistan": Afghanistan,
        "Åland Islands": ÅlandIslands,
        "Albania": Albania,
        "Algeria": Algeria,
        "American Samoa": AmericanSamoa,
        "Andorra": Andorra,
        "Angola": Angola,
        "Anguilla": Anguilla,
        "Antarctica": Antarctica,
        "Antigua and Barbuda": AntiguaAndBarbuda,
        "Argentina": Argentina,
        "Armenia": Armenia,
        "Aruba": Aruba,
        "Australia": Australia,
        "Austria": Austria,
        "Azerbaijan": Azerbaijan,
        "Bahamas": Bahamas,
        "Bahrain": Bahrain,
        "Bangladesh": Bangladesh,
        "Barbados": Barbados,
        "Belarus": Belarus,
        "Belgium": Belgium,
        "Belize": Belize,
        "Benin": Benin,
        "Bermuda": Bermuda,
        "Bhutan": Bhutan,
        "Bolivia, Plurinational State of": Bolivia,
        "Bolivia": Bolivia,
        "Bonaire, Sint Eustatius and Saba": Bonaire,
        "Bosnia and Herzegovina": Bosnia,
        "Botswana": Botswana,
        "Bouvet Island": BouvetIsland,
        "Brazil": Brazil,
        "British Indian Ocean Territory": BritishIndianOceanTerritory,
        "Brunei Darussalam": Brunei,
        "Brunei": Brunei,
        "Bulgaria": Bulgaria,
        "Burkina Faso": BurkinaFaso,
        "Burma": Burma,
        "Burundi": Burundi,
        "Cambodia": Cambodia,
        "Cameroon": Cameroon,
        "Canada": Canada,
        "Cape Verde": CapeVerde,
        "Cayman Islands": CaymanIslands,
        "Central African Republic": CentralAfricanRepublic,
        "Chad": Chad,
        "Chile": Chile,
        "China": China,
        "Christmas Island": ChristmasIsland,
        "Cocos (Keeling) Islands": CocosIslands,
        "Colombia": Colombia,
        "Comoros": Comoros,
        "Congo, the Democratic Republic of the": CongoDemocraticRepublic,
        "Congo": Congo,
        "Cook Islands": CookIslands,
        "Costa Rica": CostaRica,
        "Côte d'Ivoire": CôtedIvoire,
        "Croatia": Croatia,
        "Cuba": Cuba,
        "Curaçao": Curaçao,
        "Cyprus": Cyprus,
        "Czech Republic": CzechRepublic,
        "Denmark": Denmark,
        "Djibouti": Djibouti,
        "Dominica": Dominica,
        "Dominican Republic": DominicanRepublic,
        "Ecuador": Ecuador,
        "Egypt": Egypt,
        "El Salvador": ElSalvador,
        "Equatorial Guinea": EquatorialGuinea,
        "Eritrea": Eritrea,
        "Estonia": Estonia,
        "Ethiopia": Ethiopia,
        "Falkland Islands (Malvinas)": FalklandIslands,
        "Faroe Islands": FaroeIslands,
        "Fiji": Fiji,
        "Finland": Finland,
        "France": France,
        "French Guiana": FrenchGuiana,
        "French Polynesia": FrenchPolynesia,
        "French Southern Territories": FrenchSouthernTerritories,
        "Gabon": Gabon,
        "Gambia": Gambia,
        "Georgia": Georgia,
        "Germany": Germany,
        "Ghana": Ghana,
        "Gibraltar": Gibraltar,
        "Greece": Greece,
        "Greenland": Greenland,
        "Grenada": Grenada,
        "Guadeloupe": Guadeloupe,
        "Guam": Guam,
        "Guatemala": Guatemala,
        "Guernsey": Guernsey,
        "Guinea-Bissau": GuineaBissau,
        "Guinea": Guinea,
        "Guyana": Guyana,
        "Haiti": Haiti,
        "Heard Island and McDonald Islands": HeardIsland,
        "Holy See (Vatican City State)": HolySee,
        "Honduras": Honduras,
        "Hong Kong": HongKong,
        "Hungary": Hungary,
        "Iceland": Iceland,
        "India": India,
        "Indonesia": Indonesia,
        "Iran, Islamic Republic of": Iran,
        "Iraq": Iraq,
        "Ireland": Ireland,
        "Isle of Man": IsleOfMan,
        "Israel": Israel,
        "Italy": Italy,
        "Ivory Coast": IvoryCoast,
        "Jamaica": Jamaica,
        "Japan": Japan,
        "Jersey": Jersey,
        "Jordan": Jordan,
        "Kazakhstan": Kazakhstan,
        "Kenya": Kenya,
        "Kiribati": Kiribati,
        "Korea, Democratic People's Republic of": KoreaDemocraticRepublic,
        "Korea, Republic of": KoreaRepublic,
        "Kosovo": Kosovo,
        "Kuwait": Kuwait,
        "Kyrgyzstan": Kyrgyzstan,
        "Lao People's Democratic Republic": Lao,
        "Latvia": Latvia,
        "Lebanon": Lebanon,
        "Lesotho": Lesotho,
        "Liberia": Liberia,
        "Libya": Libya,
        "Libyan Arab Jamahiriya": Libya,
        "Liechtenstein": Liechtenstein,
        "Lithuania": Lithuania,
        "Luxembourg": Luxembourg,
        "Macao": Macao,
        "Macedonia, the former Yugoslav Republic of": Macedonia,
        "Madagascar": Madagascar,
        "Malawi": Malawi,
        "Malaysia": Malaysia,
        "Maldives": Maldives,
        "Mali": Mali,
        "Malta": Malta,
        "Marshall Islands": MarshallIslands,
        "Martinique": Martinique,
        "Mauritania": Mauritania,
        "Mauritius": Mauritius,
        "Mayotte": Mayotte,
        "Mexico": Mexico,
        "Micronesia, Federated States of": Micronesia,
        "Moldova, Republic of": Moldova,
        "Monaco": Monaco,
        "Mongolia": Mongolia,
        "Montenegro": Montenegro,
        "Montserrat": Montserrat,
        "Morocco": Morocco,
        "Mozambique": Mozambique,
        "Myanmar": Myanmar,
        "Namibia": Namibia,
        "Nauru": Nauru,
        "Nepal": Nepal,
        // "Netherlands Antilles": NetherlandsAntilles,
        "Netherlands": Netherlands,
        "New Caledonia": NewCaledonia,
        "New Zealand": NewZealand,
        "Nicaragua": Nicaragua,
        "Niger": Niger,
        "Nigeria": Nigeria,
        "Niue": Niue,
        "Norfolk Island": Norfolk,
        "Northern Mariana Islands": Mariana,
        "Norway": Norway,
        "Oman": Oman,
        "Pakistan": Pakistan,
        "Palau": Palau,
        "Palestinian Territory, Occupied": Palestine,
        "Panama": Panama,
        "Papua New Guinea": Papua,
        "Paraguay": Paraguay,
        "Peru": Peru,
        "Philippines": Philippines,
        "Pitcairn": Pitcairn,
        "Poland": Poland,
        "Portugal": Portugal,
        "Puerto Rico": PuertoRico,
        "Qatar": Qatar,
        "Réunion": Réunion,
        "Romania": Romania,
        "Russia": Russia,
        "Russian Federation": Russia,
        "Rwanda": Rwanda,
        "Saint Barthélemy": SaintBarthélemy,
        "Saint Helena, Ascension and Tristan da Cunha": SaintHelena,
        "Saint Kitts and Nevis": SaintKitts,
        "Saint Lucia": SaintLucia,
        "Saint Martin (French part)": SaintMartin,
        "Saint Pierre and Miquelon": SaintPierre,
        "Saint Vincent & the Grenadines": SaintVincent,
        "Saint Vincent and the Grenadines": SaintVincent,
        "Samoa": Samoa,
        "San Marino": SanMarino,
        "Sao Tome and Principe": SaoTome,
        "Saudi Arabia": SaudiArabia,
        "Senegal": Senegal,
        "Serbia": Serbia,
        "Seychelles": Seychelles,
        "Sierra Leone": SierraLeone,
        "Singapore": Singapore,
        "Sint Maarten (Dutch part)": SintMaarten,
        "Slovakia": Slovakia,
        "Slovenia": Slovenia,
        "Solomon Islands": SolomonIslands,
        "Somalia": Somalia,
        "South Africa": SouthAfrica,
        "South Georgia and the South Sandwich Islands": SouthGeorgia,
        "South Korea": SouthKorea,
        "South Sudan": SouthSudan,
        "Spain": Spain,
        "Sri Lanka": SriLanka,
        "St. Vincent and the Grenadines": Vincent,
        "Sudan": Sudan,
        "Suriname": Suriname,
        "Svalbard and Jan Mayen": Svalbard,
        "Swaziland": Swaziland,
        "Sweden": Sweden,
        "Switzerland": Switzerland,
        "Syrian Arab Republic": Syria,
        "Taiwan": Taiwan,
        "Tajikistan": Tajikistan,
        "Tanzania, United Republic of": Tanzania,
        "Thailand": Thailand,
        "Timor-Leste": Timor,
        "Togo": Togo,
        "Tokelau": Tokelau,
        "Tonga": Tonga,
        "Trinidad and Tobago": Trinidad,
        "Tunisia": Tunisia,
        "Turkey": Turkey,
        "Turkmenistan": Turkmenistan,
        "Turks and Caicos Islands": Turks,
        "Tuvalu": Tuvalu,
        "Uganda": Uganda,
        "Ukraine": Ukraine,
        "United Arab Emirates": UAE,
        "United Kingdom": UnitedKingdom,
        "United States Minor Outlying Islands": USMOI,
        "United States": USA,
        "Uruguay": Uruguay,
        "Uzbekistan": Uzbekistan,
        "Vanuatu": Vanuatu,
        "Venezuela, Bolivarian Republic of": Venezuela,
        "Venezuela": Venezuela,
        "Viet Nam": Vietnam,
        "Vietnam": Vietnam,
        "Virgin Islands, British": VIB,
        "Virgin Islands, U.S.": VIU,
        "Wallis and Futuna": Wallis,
        "Western Sahara": WesternSahara,
        "Yemen": Yemen,
        "Zambia": Zambia,
        "Zimbabwe": Zimbabwe,
    }

    return flagMap[countryName] ? <Image src={flagMap[countryName]} width={"36px"} height={"24px"} /> : null
}

export default CountryFlag