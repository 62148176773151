import "./TermsPage-style.scss"
import {
    Link,
} from 'react-router-dom';


function TermsPage() {

    return (
        <div className="terms-wrap">
            <div className="terms-body">
                <div className="terms-title"><p>Droplinked.io and Shopsadiq Limited Inc® Mobile App Terms of Service</p> </div>
                <div className="terms-text">
                    <p> The Shopsadiq Limited Inc mobile app (“Shopsadiq Limited Inc”) is available for Android OS and Apple iOS devices and offers you to discover and share collections of products. Shopsadiq Limited Inc Inc., a Delaware company, is the genius behind and owner of Shopsadiq Limited Inc. The terms of this agreement (“Agreement”) make up a binding legal agreement between you, as an individual or entity, and “Shopsadiq Limited Inc.” By accessing the Shopsadiq Limited Inc website and/or downloading Shopsadiq Limited Inc you agree to be bound by both this Agreement, our privacy policy (“Privacy Policy,” available for download at privacy page) and our Shopsadiq Limited Inc Card Terms of Service (“Shopsadiq Limited Inc Card Terms,” available for download at www.Droplinked.io/terms). Please review all three agreements carefully before using the Services, including installing Shopsadiq Limited Inc and setting up your Shopsadiq Limited Inc account, as you do agree to be bound by all of the agreements. </p>
                    <p> In this Agreement, the word “Service” or “Services” will refer to all content available on our Site, as well as the mobile version of the Site, and the Shopsadiq Limited Inc Apps. Additional services are available within Shopsadiq Limited Inc and these services are governed by their respective terms, available for download at terms page. Users of Shopsadiq Limited Inc may also use the Shopsadiq Limited Inc card program (“Shopsadiq Limited Inc Card”) and the terms of use for the Shopsadiq Limited Inc Card are available at terms page. The content available on the app in Shopsadiq Limited Inc may differ from the content available on the Site and vice versa. </p>
                    <p> Visitors to our Site (“Visitors”), individuals who have downloaded and installed Shopsadiq Limited Inc and setup an account with Shopsadiq Limited Inc (“Shopsadiq Limited Inc Members”) and Shopsadiq Limited Inc Members who have signed up for a Shopsadiq Limited Inc Card (“Shopsadiq Limited Inc Card Members”) are all covered by this Agreement. You will be asked to accept this Agreement when you become a Shopsadiq Limited Inc Card Member. You should save a copy of this Agreement for your records. </p>
                    <p> We of course welcome any comments or suggestions from any of these users on all of our Services at support (at) Shopsadiq Limited Inc.io. </p>
                    <p> To the extent you have access to or are using Shopsadiq Limited Inc or the Site without having completed an agreement with Shopsadiq Limited Inc, Inc., you are hereby notified that your continued use of the Services is nonetheless subject to many of the terms and conditions of this Agreement. </p>
                    <p><span>NOTICE: </span>Please take note that this Agreement contains disclaimers of warranties and liability. </p>
                    <p><span>Binding Effect and Agreement Changes.</span> This is a binding agreement. By using the Site or any services provided in connection with the Site, you agree to abide by this Agreement, as they may be amended by Shopsadiq Limited Inc, Inc. from time to time in its sole discretion. Shopsadiq Limited Inc, Inc. will post a notice on the Site any time this Agreement has been changed or otherwise updated. It is your responsibility to review this Agreement periodically, and if at any time you find this Agreement unacceptable, you must immediately leave the Site and cease all use of the Site and Shopsadiq Limited Inc. YOU AGREE THAT BY USING THE SERVICE YOU REPRESENT THAT YOU ARE AT LEAST 13 YEARS OLD AND THAT YOU ARE LEGALLY ABLE TO ENTER INTO THIS AGREEMENT. </p>
                    <p><span>Accessing the Services.</span> Although information about Shopsadiq Limited Inc is available on the Site, you need to download and install Shopsadiq Limited Inc and fully setup your profile to get the most out of the Services.Further, you will need to setup your Shopsadiq Limited Inc Card as described in the Shopsadiq Limited Inc Card Terms. </p>
                    <p> You may not reverse engineer, decompile, disassemble or otherwise attempt to discern the source code of the components of Shopsadiq Limited Inc, nor may you reproduce all or any portion of the components of Shopsadiq Limited Inc or distribute any components without Shopsadiq Limited Inc, Inc.’s written permission. The contents of the Site and Shopsadiq Limited Inc belong to Shopsadiq Limited Inc, Inc. and are protected through trademark and copyright laws. All contents of the Site and Shopsadiq Limited Inc are: Copyright © 2017 Shopsadiq Limited Inc, Inc., ADGM Al Khatem Tower Abu Dhabi, UAE. All rights reserved. </p>
                    <p> From time to time Shopsadiq Limited Inc, Inc. may update Shopsadiq Limited Inc. This may also mean that you are not able to access and/or use Shopsadiq Limited Inc fully or partly as you once were with an existing device. As of the effective date of this Agreement, the minimum requirements for Shopsadiq Limited Inc are: an Apple iOS device running iOS 8 or higher that you own or control or an Android OS device running Android OS 2.1 up to OS 2.3 with an account for either device that allows access to the Play Store or Apple App Store in a country that offers Shopsadiq Limited Inc. Further, Shopsadiq Limited Inc requires an Internet connection to fully function. As Shopsadiq Limited Inc, Inc. does not provide your Internet connection, it makes no warranties that you will, in fact, always have access to the Internet. </p>
                    <p><span>Your Shopsadiq Limited Inc Card.</span> We reserve the right to refuse a Shopsadiq Limited Inc Card to anyone for any reason within the confines of the law. You also take full responsibility for keeping your username and password confidential, and you agree to contact us immediately at support (at) Shopsadiq Limited Inc.io if you think your Shopsadiq Limited Inc Profile has been compromised. </p>
                    <p> You will be asked during account setup and periodically during account maintenance to authorize Shopsadiq Limited Inc, Inc. to retrieve information about you that you keep with other third parties (e.g., retailers, e-commerce sites). We will retrieve the information but we do not vet the information (for example, for accuracy, legality or infringement). Because this will require Shopsadiq Limited Inc to contact third parties, this may mean that our ability to access information is limited by the third party’s ability to provide access to the information. Our Privacy Policy, available at privacy page, governs our management of this information. </p>
                    <p> Scope of the Service. Shopsadiq Limited Inc offers you tools that can help you track your spending and find trends. This means that the Services include the delivery of the graphics and/or images, text, audio, video, data compilations, electronic communication, software, databases, and other visuals or audio. This also means that you your personal inputs are necessary to get the most out of Shopsadiq Limited Inc and we may provide you with information related to third party products, services, tips and/or suggestions. </p>
                    <p><span>Shopsadiq Limited Inc DOES NOT PROVIDE ANY INVESTMENT ADVICE, TAX ADVICE OR LEGAL ADVICE.</span> The Site and Shopsadiq Limited Inc are there for you to educate yourself; the Service is provided as is and is solely for informational purposes, not for advice. Shopsadiq Limited Inc, Inc. urges you to consider seeking advice from a financial planner, an attorney or tax advisor regarding your financial matters. None of the information contained on the Site or in the Service constitutes a solicitation, offer, opinion or recommendation by Shopsadiq Limited Inc, Inc. or any of its third-party data providers, to buy or sell any securities or other financial instruments or to provide legal, tax, accounting or investment advice or services regarding the viability, suitability or potential profitability of any security or investment. </p>
                    <p> Shopsadiq Limited Inc, Inc. makes a reasonable effort to ensure that the Service is up to date and accurate, and you acknowledge and agree that Shopsadiq Limited Inc relies on the accuracy of outside sources, which may or may not be completely thorough and accurate. Shopsadiq Limited Inc reserves the right to correct any information provided and makes no warranties as to the accuracy or completeness of the information provided. Neither Shopsadiq Limited Inc, Inc. nor any outside content providers shall be liable for any errors, inaccuracies or delays in content, or for any actions taken in reliance thereon. AS FURTHER DISCUSSED BELOW, Shopsadiq Limited Inc EXPRESSLY DISCLAIMS ALL WARRANTIES, EXPRESSED OR IMPLIED, AS TO THE ACCURACY OF ANY THE CONTENT PROVIDED, OR AS TO THE FITNESS OF THE INFORMATION FOR ANY PURPOSE. </p>
                    <p> Please report any errors or mistakes you may find to support (at) Shopsadiq Limited Inc.io and be sure to include a detailed description of the issue, the URL(s) where the error occurs, and which internet browser you were using. </p>
                    <p> Please also note that some functionality will depend on your other service providers providing access to certain information. For example, some platforms are not setup to interface directly with third party programs, but may allow you to download the relevant data and that data can then be uploaded here. Further, your financial institution may limit access to certain types of accounts (e.g., credit card, savings account). Shopsadiq Limited Inc can help you overcome some of these issues, but others problems may remain until your financial institution takes steps to open its platform for integration. </p>
                    <p> Although promotional credit and coupons may be available to users of Shopsadiq Limited Inc and/or the Shopsadiq Limited Inc Card, Shopsadiq Limited Inc is not a money transmitter or money transfer service. </p>
                    <p><span>Rights Grant; Restrictions.</span></p>
                    <p> The Site and Service may contain original works created by Shopsadiq Limited Inc. These works are Shopsadiq Limited Inc Inc.’s exclusive intellectual property and are proprietary. As such, the Site and Service are protected by this Agreement, domestic and foreign contractual and intellectual property law (such as, but not limited to patent, copyright and trademark laws), which is further discussed below. </p>
                    <p> You are granted a non-exclusive, non­-transferable limited right to access the Site and Service for research purposes that can be revoked. Every natural or legal person requires an individual user account, and you may not share your username and password with another person, legal or natural. </p>
                    <p> The Service is protected by copyright, intellectual property laws, and other laws that prevent any and all unauthorized use, access and manipulation. Shopsadiq Limited Inc grants to you only the following rights: (a) the right to electronically display the Service through the Site web portal for your use, (b) the right to display the Service to others in the course of ordinary business, (c) the right to use the Service as permitted by applicable copyright law(s), and (d) the right to print portions of the Service on an ad hoc basis for your personal purpose(s). You are prohibited from offering for commercial resale or distribution any of the Service, and you are prohibited from using any of the Service or the Site or any of its intellectual property to compete with Shopsadiq Limited Inc. This section is the limit of your rights, and this Agreement does not grant to you any propriety interest in any Shopsadiq Limited Inc property. Your use must comply at all times with all applicable laws, rules or regulations. </p>
                    <p> You may not copy, download, store, publish, transmit, retransmit, transfer, distribute, disseminate, circulate, sell, resell or otherwise use the Service or any portion of the Service, in any form or by any means, except (i) as expressly permitted by this Agreement, (ii) with Shopsadiq Limited Inc’s prior written permission, or (iii) if not otherwise expressly prohibited by this Agreement or as allowed under the fair use provision of the Copyright Act (17 U.S.C.A. § 107). ICE may also discontinue or change the Service, or its availability to you, at any time. </p>
                    <p> Nothing contained on the Site or in the app should be understood as granting you a license to use any of the trademarks, service marks, or logos owned by Shopsadiq Limited Inc or by any third party. </p>
                    <p><span>Use.</span> The Service is to only be used in accordance with this Agreement. Shopsadiq Limited Inc retains the right to temporarily or permanently block access to the Services (at its sole discretion) if Shopsadiq Limited Inc has reason to believe that the Services may be used for an improper purpose or otherwise in violation of the terms of this Agreement. You must report to Shopsadiq Limited Inc any misuse, abuse, or compromise of the Service of which you become aware. </p>
                    <p> Shopsadiq Limited Inc makes it possible for you to use sites and services outside of Shopsadiq Limited Inc to distribute referral links to your network. Be mindful of your conduct both inside the Shopsadiq Limited Inc App and outside as it relates to Shopsadiq Limited Inc. For example, do not engage in unauthorized commercial communications or multi-level marketing schemes, solicit and/or collect App login information, collect users' content or information using traditional or automated means, or access an account belonging to someone else. If you have any questions about your conduct, please let us know. </p>
                    <p> Further, we may allow users to upload lifestyle content such as images and then tag them with brands or stores as part of creating a user’s shopping collection. These photos and the information that is attached to the photos are the expression of the opinions and information of the user posting the photo. Shopsadiq Limited Inc neither agrees or approves of the opinions or information, nor does either warrant the exhaustiveness, suitability, or accuracy of the opinion or information. </p>
                    <p> As a result of the user’s experience and feedback or other reasons, we may from time to time make images or logos of various companies available on our website or via the Shopsadiq Limited Inc App. Use of these photos does not mean that any of the owners or licensees of this contact are providing an endorsement, authorization, sponsorship, or affiliation with Shopsadiq Limited Inc. </p>
                    <p><span>Account Security.</span> As mentioned above in this agreement as well as in the Shopsadiq Limited Inc Card Terms,if you have a reason to believe that your account is no longer secure (for example, in the event of a loss, theft or unauthorized disclosure or use of your ID, password, or any credit, debit, or charge card number stored), notify Shopsadiq Limited Inc of the problem to avoid possible liability for any unauthorized charges, access or use of your Shopsadiq Limited Inc Profile. </p>
                    <p> At Shopsadiq Limited Inc, we value and encourage innovation and collaboration. We work to bring the most efficient, elegant solutions to streamline the Shopsadiq Limited Inc App. This means that we may work with strategic partners for services, including payment processing or customer data management. Your use of the Shopsadiq Limited Inc products means you consent to our use of these third parties. We may share your information with these third parties subject to the provisions in our Privacy Policy, as amended. </p>
                    <p><span>Mobile and Email Alerts.</span> Your Android or Apple device may provide Shopsadiq Limited Inc the opportunity to send you alerts and/or messages through your device and/or email. You can make changes to these preferences within your Shopsadiq Limited Inc Profile. If your contact information used in your Shopsadiq Limited Inc Profile changes, then you are responsible for updating your Shopsadiq Limited Inc Profile timely. We will never send your password through an alert. </p>
                    <p><span>Privacy Policy.</span> Shopsadiq Limited Inc respects your privacy. A complete statement of Flataly’s current privacy policy can be found by visiting privacy page. Shopsadiq Limited Inc’s privacy policy is expressly incorporated into this Agreement by this reference. Any notice(s) of changes to the Privacy Policy will be posted to the Shopsadiq Limited Inc website. </p>
                    <p><span>Regional Concerns.</span> Shopsadiq Limited Inc, Inc. is a Delaware company and its clients are residents of the United States. Selected services may be available to those outside of the U.S. Contact us for more information! </p>
                    <p> You are given access to the Site and Shopsadiq Limited Inc based on your home location. To comply with local laws, you may not use Shopsadiq Limited Inc outside of your home country for more than thirty (30) days at one time. Shopsadiq Limited Inc reserves the right to suspend access should Shopsadiq Limited Inc suspect that a user has accessed Shopsadiq Limited Inc for more than the proscribed time period. Your use of or access to the Site and/or Shopsadiq Limited Inc should not be construed as Shopsadiq Limited Inc’s purposefully availing itself of the benefits or privilege of doing business in any state or jurisdiction other than Delaware. </p>
                    <p> You acknowledge that you are not located in a country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a “terrorist supporting” country, and (ii) you are not listed on any U.S. Government list of prohibited or restricted parties. Further, you recognize that the Shopsadiq Limited Inc Mobile App may ask you for your location when you use it to give you targeted, geographically specific information. You can disable or enable these settings within your device. Contact your device manufacturer for more information. </p>
                    <p> Change to Agreement. As mentioned above, Shopsadiq Limited Inc may change the terms of this Agreement at any time by notifying you of the change in writing or electronically (including without limitation, by email or by posting a notice on the Site that the terms have been “updated” or similar language). You agree to be bound by such changes by using Service after changes are made to this Agreement. If you do not consent to any part of the Agreement, please do not access the Service as your access constitutes your acceptance of these terms. </p>
                    <p><span>Downloading Shopsadiq Limited Inc from Apple Inc. (“Apple”)?</span> If you downloaded Shopsadiq Limited Inc from Apple, then the following terms of this Agreement also apply: </p>
                    <p><span>Acknowledgement.</span> You acknowledge that this Agreement is concluded between you and Shopsadiq Limited Inc and not between you and Apple. </p>
                    <p> Maintenance and Support. Apple has no obligation whatsoever to furnish any maintenance and support services with respect to Shopsadiq Limited Inc. </p>
                    <p> Warranty. In the event of any failure of Shopsadiq Limited Inc to conform to any applicable warranty, you may notify Apple, and Apple will refund your purchase price, if any, for Shopsadiq Limited Inc; to the maximum extent permitted by applicable law, Apple will have no other warranty obligation whatsoever with respect to Shopsadiq Limited Inc. </p>
                    <p> Product Claims. Shopsadiq Limited Inc, and not Apple, is responsible for addressing any of your claims or any third party relating to Shopsadiq Limited Inc or your possession and/or use of Shopsadiq Limited Inc, including, but not limited to: (i) product liability claims; (ii) any claim that the Shopsadiq Limited Inc fails to conform to any applicable legal or regulatory requirement; and (iii) claims arising under consumer protection or similar legislation. </p>
                    <p> Intellectual Property Rights. In the event of any third party claim that Shopsadiq Limited Inc or your possession and use of Shopsadiq Limited Inc infringes that third party’s intellectual property rights, Shopsadiq Limited Inc, not Apple, will be solely responsible for the investigation, defense, settlement and discharge of any such intellectual property infringement claim. </p>
                    <p> Developer Name and Address. Shopsadiq Limited Inc Inc., ADGM Al Khatem Tower Abu Dhabi, UAE. </p>
                    <p> Third Party Beneficiary. Apple and Apple’s subsidiaries are third party beneficiaries of this Agreement. Upon your acceptance of the terms and conditions of this Agreement, Apple will have the right (and will be deemed to have accepted the right) to enforce the Agreement against the end-user as a third party beneficiary thereof. </p>
                    <p> Performance. Shopsadiq Limited Inc will use commercially reasonable efforts to deliver the Services, provided that you accept all information “as is.” You acknowledge and agree that Shopsadiq Limited Inc relies on the accuracy of outside sources, which may or may not be completely thorough and accurate and you will not rely on Shopsadiq Limited Inc for the accuracy or completeness of the Services. Shopsadiq Limited Inc reserves the right to add or subtract from the material presented in the Services. This may include discontinuing part of the Service. In the event that Shopsadiq Limited Inc removes a material portion of the Service that you regularly and reasonably use in the course of your business, upon your timely request following the removal or announced removal, Shopsadiq Limited Inc may provide a prorated credit to your account, as also noted above. ICE does not provide professional investment advice. </p>
                    <p> Some features of the Shopsadiq Limited Inc Mobile App may require you to have a social media account (e.g., Facebook Messenger, Twitter), including the chat function. Your social media accounts are subjects to the terms and conditions of the individual media providers. </p>
                    <p> California Consumer Rights Notice. The Site and the app may be used by both consumers and professionals. Pursuant to California Civil Code Sec. 1789.3, Shopsadiq Limited Inc is required to provide the following information. The Site and Shopsadiq Limited Inc are provided by Shopsadiq Limited Inc, Inc. located at ADGM Al Khatem Tower Abu Dhabi, UAE and reachable by email at support (at) Shopsadiq Limited Inc.io. Access to the Site is free to everyone; access to Shopsadiq Limited Inc is by license and download only and subject to these terms. </p>
                    <p> If you would like to receive further information regarding the use of our service, or if you have any questions or complaints please feel free to contact Shopsadiq Limited Inc directly to have the issue resolved. Please note that Shopsadiq Limited Inc cannot provide a specific timeframe for any resolution. Should you remain unsatisfied with the service, you can also contact the Complaint Assistance Unit of the Division of Consumer Service of the Department of Consumer Affairs; they are reachable in writing at 1625 North Market Blvd., Suite N 112, Sacramento, CA 95834 or by telephone at (800) 952-5210. </p>

                    <p><span>LIMITATION OF LIABILITY.</span></p>
                    <p> Shopsadiq Limited Inc’S LIABILITY TO YOU IS LIMITED. TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT SHALL COMPANY BE LIABLE FOR DAMAGES OF ANY KIND (INCLUDING, BUT NOT LIMITED TO, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES, LOST PROFITS, OR LOST DATA, REGARDLESS OF THE FORESEEABILITY OF THOSE DAMAGES) ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF THE SITE OR ANY OTHER MATERIALS OR SERVICES PROVIDED TO YOU BY THE COMPANY. This limitation shall apply regardless of whether the damages arise out of breach of contract, tort, or any other legal theory or form of action. </p>
                    <p> You acknowledge that any reliance upon any of the opinions, facts, or recommendations provide in the Service is at your own risk. </p>
                    <p><span>LIMITED WARRANTY.</span></p>
                    <p> Shopsadiq Limited Inc HEREBY DISCLAIMS ALL WARRANTIES. COMPANY IS MAKING THE SITE AVAILABLE “AS IS” WITHOUT WARRANTY OF ANY KIND. YOU ASSUME THE RISK OF ANY AND ALL DAMAGE OR LOSS FROM USE OF, OR INABILITY TO USE, THE SITE OR THE SERVICE. TO THE MAXIMUM EXTENT PERMITTED BY LAW, COMPANY EXPRESSLY DISCLAIMS ANY AND ALL WARRANTIES, EXPRESS OR IMPLIED, REGARDING THE SITE, INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED WARRANTIES OF ACCURACY, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT. COMPANY DOES NOT WARRANT THAT THE SITE OR THE SERVICE WILL MEET YOUR REQUIREMENTS OR THAT THE OPERATION OF THE SITE OR THE SERVICE WILL BE UNINTERRUPTED OR ERROR-FREE. YOU ASSUME FULL RESPONSIBILITY FOR YOUR USE OF THE SITE AND SERVICES AND ANY THIRD-PARTY LINKS, AND YOUR REMEDY LINKED TO THE SITE AND/OR SERVICES IS LIMITED TO STOP USING THE SITE. </p>
                    <p><span>Governing Law.</span> This Agreement shall be construed in accordance with and governed by the laws of the United States and the State of California, without reference to their rules regarding conflicts of law. You hereby irrevocably consent to the exclusive jurisdiction of the state or federal courts in Los Angeles County, California in all disputes arising out of or related to the use of the Services.
                        <br />Third-Party Sites and Shopsadiq Limited Inc Merchants. Shopsadiq Limited Inc has no control over, and no liability for any third party website or materials. Shopsadiq Limited Inc works with a number of partners and affiliates whose Internet site(s) may be linked with the Site or within the app. Because Shopsadiq Limited Inc has no control over the content and performance of these partner and affiliate site, Shopsadiq Limited Inc makes no guarantees about the accuracy, currency, content, or quality of the information provided by such Site, and Shopsadiq Limited Inc assumes no responsibility for unintended, objectionable, inaccurate, misleading, or unlawful content that may reside on those sites. Similarly, from time to time in connection with your use of the Site, you may have access to content items (including, but not limited to, the website) that are owned by third parties. You acknowledge and agree that Shopsadiq Limited Inc makes no guarantees about, and assumes no responsibility for, the accuracy, currency, content, or quality of this third party content, and that, unless expressly provided otherwise, this Agreement shall govern your use of any and all third party content. Further, unless expressed otherwise, third party content does not indicate sponsorship of the Site or the Shopsadiq Limited Inc Mobile App by these third parties. </p>
                    <p> The Shopsadiq Limited Inc Mobile App contains information that is provided directly by the manufacturer or the retail seller, including, for example, product prices, sales, descriptions and sizes. Shopsadiq Limited Inc makes no representations as to the accuracy of this information. Shopsadiq Limited Inc depends on merchants to keep inventory details and availability up-to-date. The Shopsadiq Limited Inc Mobile App will confirm availability of a particular item once you go to your virtual shopping cart and again prior to completing your purchase. If a merchant is not able to ship a particular product you will be notified by email. </p>
                    <p><span>Promotion Codes. </span> Shopsadiq Limited Inc may, from time to time, make available certain promotion codes. You cannot apply a promotion code to a previously registered subscription, and promotion codes may only be made available to new subscribers. </p>
                    <p><span>Modifications. </span> Shopsadiq Limited Inc may, in its sole discretion and without prior notice, (a) revise this Agreement; (b) modify the Site and/or the Service; and/or (c) discontinue the Site and/or Shopsadiq Limited Inc at any time. Shopsadiq Limited Inc shall post any revision to this Agreement to the Site, and the revision shall be effective immediately on such posting. You agree to review this Agreement and other online policies posted on the Site periodically to be aware of any revisions. You agree that, by continuing to use or access the Site following notice of any revision, you shall abide by any such revision. </p>
                    <p><span>Digital Millennium Copyright Act Compliance.</span></p>
                    <p> The Digital Millennium Copyright Act (“DMCA”) is applicable to Internet service providers. (17 U.S.C. § 512). Our Service do not allow for a subscriber, member or account holder to upload their own information. As such, Shopsadiq Limited Inc does not foresee the applicability of the DMCA to the Service. Shopsadiq Limited Inc will, however, terminate subscribers, members or account holders, where circumstances dictate and where Shopsadiq Limited Inc deems appropriate, if these parties repeatedly infringe on another’s intellectual property rights. If you want to register a complaint regarding copyright infringement, you need to provide the following information after you have confirmed that you are, in fact, the copyright owner or rights holder:
                        <br />(i) A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.
                    </p>
                    <p> (ii) Identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works at a single online site are covered by a single notification, a representative list of such works at that site. </p>
                    <p> (iii) Identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit the service provider to locate the material. </p>
                    <p> (iv) Information reasonably sufficient to permit the service provider to contact the complaining party, such as an address, telephone number, and, if available, an electronic mail address at which the complaining party may be contacted. </p>
                    <p> (v) A statement that the complaining party has a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law. </p>
                    <p> (vi) A statement that the information in the notification is accurate, and under penalty of perjury, that the complaining party is authorized to act on behalf of the owner of an exclusive right that is allegedly infringed. </p>
                    <p><br /> Shopsadiq Limited Inc, Inc. <br /> ADGM Al Khatem Tower<br /> Abu Dhabi<br /> UAE<br /> (972) 978-6076<br /> support (at) Shopsadiq Limited Inc.io </p>
                    <p> Inappropriate Content. It is unlikely that any Shopsadiq Limited Inc or Site user will upload or make available information to others. However, should you upload, disseminate or otherwise make available information, you shall not make the following types of Content available. You agree not to upload, download, display, perform, transmit, or otherwise distribute any Content that (a) is libelous, defamatory, obscene, pornographic, abusive, or threatening; (b) advocates or encourages conduct that could constitute a criminal offense, give rise to civil liability, or otherwise violate any applicable local, state, national, or foreign law or regulation; or (c) advertises or otherwise solicits funds or is a solicitation for goods or services. Shopsadiq Limited Inc reserves the right to terminate your receipt, transmission, or other distribution of any such material using the Service, and, if applicable, to delete any such material from its servers. Shopsadiq Limited Inc intends to cooperate fully with any law enforcement officials or agencies in the investigation of any violation of this Agreement or of any applicable laws. </p>
                    <p> Prohibited Uses. Shopsadiq Limited Inc imposes certain restrictions on your permissible use of the Site and the Service. You are prohibited from violating or attempting to violate any security features of the Site or Service, including, without limitation, (a) accessing content or data not intended for you, or logging onto a server or account that you are not authorized to access; (b) attempting to probe, scan, or test the vulnerability of the Service, the Site, or any associated system or network, or to breach security or authentication measures without proper authorization; (c) interfering or attempting to interfere with service to any user, host, or network, including, without limitation, by means of submitting a virus to the Site or Service, overloading, “flooding,” “spamming,” “mail bombing,” or “crashing;” (d) using the Site or Service to send unsolicited e-mail, including, without limitation, promotions, or advertisements for products or services; (e) forging any TCP/IP packet header or any part of the header information in any e-mail or in any posting using the Service; or (f) attempting to modify, reverse-engineer, decompile, disassemble, or otherwise reduce or attempt to reduce to a human-perceivable form any of the source code used by Shopsadiq Limited Inc in providing the Site or Service. Any violation of system or network security may subject you to civil and/or criminal liability. </p>
                    <p> Shopsadiq Limited Inc makes it possible for you to use sites and services outside of Shopsadiq Limited Inc to distribute referral links to your network. Be mindful of your conduct both inside the Shopsadiq Limited Inc App and outside as it relates to Shopsadiq Limited Inc. For example, do not engage in unauthorized commercial communications or multi-level marketing schemes, solicit and/or collect App login information, collect users' content or information using traditional or automated means, or access an account belonging to someone else. If you have any questions about your conduct, please let us know. </p>
                    <p> Indemnity. You agree to indemnify Shopsadiq Limited Inc for certain of your acts and omissions. You agree to indemnify, defend, and hold harmless Shopsadiq Limited Inc, its affiliates, officers, directors, employees, consultants, agents, and representatives from any and all third party claims, losses, liability, damages, and/or costs (including reasonable attorney fees and costs) arising from your access to or use of the Site, your violation of this Agreement, or your infringement, or infringement by any other user of your account, of any intellectual property or other right of any person or entity. Shopsadiq Limited Inc will notify you promptly of any such claim, loss, liability, or demand, and will provide you with reasonable assistance, at your expense, in defending any such claim, loss, liability, damage, or cost. </p>
                    <p> Termination of your Shopsadiq Limited Inc Card. As described above and in more detail in our Shopsadiq Limited Inc Card Terms, Shopsadiq Limited Inc reserves the right to terminate your Shopsadiq Limited Inc Card and/or your access to the Shopsadiq Limited Inc Mobile App if, at Shopsadiq Limited Inc’s sole discretion, Shopsadiq Limited Inc believes that you have violated this Agreement or any terms of the Shopsadiq Limited Inc Card Terms, you have more than one Shopsadiq Limited Inc Card, a mistake was made when opening or servicing your account, or that the use of your account is unauthorized, deceptive, fraudulent or otherwise unlawful and to do so without providing notice to you and any credits in your account will expire immediately and will not be reinstated. If your Shopsadiq Limited Inc Card is inactive for two (2) years consecutively, then Shopsadiq Limited Inc reserves the right to deactivate and delete your card, including your account history and any account credits without notice. </p>
                    <p> You can delete your Shopsadiq Limited Inc Card by logging in online or by contacting us at support (at) Shopsadiq Limited Inc.io. Please allow up to ten (10) business days for your account to be deleted and remember that your account credits are deleted along with your account. We will maintain account records only as required or allowed by law. </p>
                    <p> Severability and Waiver. If, for whatever reason, a court of competent jurisdiction finds any term or condition in this Agreement to be unenforceable, all other terms and conditions will remain unaffected and in full force and effect. No waiver of any breach of any provision of this Agreement shall constitute a waiver of any prior, concurrent, or subsequent breach of the same or any other provisions hereof, and no waiver shall be effective unless made in writing and signed by an authorized representative of the waiving party. </p>
                    <p> ACKNOWLEDGEMENT. BY USING THE SERVICE OR ACCESSING THE SITE, YOU ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS OF USE AND AGREE TO BE BOUND BY THEM. </p>
                    <Link to="/privacy">
                        <button className="terms-btn"><p>PRIVACY POLICY</p></button>
                    </Link>

                </div>
            </div>
        </div>
    )
}

export default TermsPage