import "../terms-page/TermsPage-style.scss"
import { Link } from 'react-router-dom';

export default function PrivacyPage() {

    return (
        <div className="terms-wrap">
            <div className="terms-body">
                <div className="terms-title">
                    <p>Shopsadiq Limited Inc. Privacy Policy</p>
                </div>
                <div className="terms-text">
                    <p> Shopsadiq Limited Inc.(the “Company” or “Shopsadiq Limited Inc”) prides itself on ensuring the privacy of its clientele and other visitors to its website (the “Site” or “website”) and users of its mobile applications, including the Shopsadiq Limited Inc Card (collectively “Mobile Apps”). This privacy policy is but one step we take in our commitment to the fair treatment of private information and protecting your privacy rights. Please note that your use of the Mobile Apps is also governed by other agreements available on our website. </p>
                    <p> This policy is an agreement between the Company, the operator of the Site and the Mobile Apps, and you as the user of our Site and the Mobile Apps and of the Shopsadiq Limited Inc program and Shopsadiq Limited Inc Card. This agreement tells you when this policy is effective, what information we collect from you, how we might use it, how you can review or change the personal information you provided to us, how we may change this policy, and the steps we take to ensure that your personal information is protected. Should you need to contact us regarding this policy, the Site, or the Mobile Apps please scroll to the bottom to find our contact information. </p>
                    <p><span>Agreement to the terms of the policy and terms of use</span><br /> This policy covers information collected only on the Site, through our Mobile Apps or by way of setting up a Shopsadiq Limited Inc account or a Shopsadiq Limited Inc Card. If you setup and maintain a Premium Shopsadiq Limited Inc account or a Platinum Shopsadiq Limited Inc account, these account types may be governed by additional privacy terms and conditions. </p>
                    <p> This Agreement covers both visitors to the Site as well as registered users of the Site and the Mobile Apps. All activities in which you may engage on the Site and/or the Mobile Apps are voluntary. You are not required to provide any personal information to us unless you choose to access features on the Site that require the information, or if you choose to provide information to a third party to use the Site or the Mobile Apps (e.g., iTunes). If you do not agree with the terms of this policy and/or other terms of use on the Site or for the Mobile Apps, then you should not provide us with personal information, immediately exit the website or uninstall the Mobile Apps. You should only download the app if you agree to these terms. You can contact us with the information below should you wish to delete your account. More information about deleting your account or modifying your account </p>
                    <p> Although we do operate the Site and the Mobile Apps, the nature of our products and services requires interaction with third parties and third party websites as well as third party service providers. We do not own, operate, maintain or control these third party websites or service providers, and this policy only serves to inform you of our own internal policies.<br /> The terms of use of the website and the Mobile Apps are expressly incorporated herein by reference and made a part of this policy. This privacy policy is available at privacy page and we request that you read this policy carefully as it impacts you directly. Keep in mind also that the policy may impact you differently depending on your level of interaction with the Site and the Mobile Apps. </p>
                    <p><span>Effective date &amp; agreement changes</span><br /> This privacy policy is effective as of the date above (Last Updated date) and will remain in effect except with respect to any of its provisions that are changed in the future, in which case the changes will become effective on the date they are posted on the website or we otherwise notify you of the changes. We reserve the right to change this privacy policy at any time. Changes, modifications, additions, or deletions will be effective immediately on their posting to the website. You should check this policy periodically as its terms may change from time to time. Your continued use of the site after we post any such modifications will constitute your acknowledgment of the modified policy and your agreement to abide and be bound by the modified policy. We will also revise the Last Updated date (which is also the effective date) found at the beginning of this privacy policy when we post changes to it. </p>
                    <p><span>What information do we collect?</span><br /> The privacy policy does not extend to anything that is inherent in the operation of the Internet, and therefore beyond Company’s control, and is not to be applied in any manner contrary to applicable law or governmental regulation. This online privacy policy only applies to information collected through the Site, through the Mobile Apps, through the Shopsadiq Limited Inc Card and account (subject to additional privacy and terms and conditions that may apply to the Premium and Platinum accounts. </p>
                    <p> The information we collect can be generally broken down into three categories: (i) information that you provide to us, (ii) information we collect from you while you use our Site, the Mobile Apps or your account, and (iii) information we collect from elsewhere. Each of these categories may include general information, personal information, and sensitive information and/or location information. We will not ask for your Social Security Number or driver’s license unless we or a program partner (“Shopsadiq Limited Inc Merchant”) are providing a financial product to you that requires us to collect special information from our customers (Know Your Customer) (for example, in the case of a Platinum or Premium account). </p>
                    <p><span>Information You Give Us</span><br /> We may ask you for certain information referred to on this site as “personal information,” (also known as personally identifiable information) which includes information that pertains to your identity. Such information may include, but is not limited to, items such as your name, address, and date of birth, age, gender, e-mail address, or telephone number. If you do not provide the requested personal information, you may not be able to access or use the features of the site, app, or account where such information is requested. When you register for an account with us, we will ask you for personal information. We may also solicit your opinion or request feedback from you regarding the Site or the Mobile Apps. This information may or may not include personal information. </p>
                    <p> In addition to the personal information above, full functionality of the Shopsadiq Limited Inc Card may require that you provide what we call “sensitive information.” This sensitive information is information related to your accounts with financial institutions, including your login information for online banking or other financial platforms as well as any payment information you provide to us. This information is protected in the same way as your personal information, and we take the protection of all of your information seriously. We need this information in order to provide you with tailored services, and we may use this sensitive information to make recommendations to you regarding certain products or services. </p>
                    <p> You alone are responsible for whether or not you provide us with sensitive information. As mentioned above, if you choose not to provide certain information either during the account registration or later, you may have limited functionality of both the Site and the Mobile Apps. </p>
                    <p><span>Information We Collect When You Visit the Site or Use the Mobile Apps</span><br /> When you first come to the Site we collect information that does not have to identify you personally. We refer to this information as general information. There may be other instances in which we collect information from you that are more general in nature (“general information”). Such information may include your Internet Protocol (IP) address, which enables us to identify your computer or device on a Transmission Control Protocol/Internet Protocol (TCP/IP) network, which includes the World Wide Web. Your computer has an IP address, which is required in order for you to communicate with others via the Internet. Such general information, including the click stream data and cookies, as mentioned below, may be collected from you whenever you access the site, whether or not you provide us with any personal information. When you use our Mobile Apps, we may also collect your device’s unique device identifier assigned to that device by phone makers, carriers, or makers of the operating system. </p>
                    <p> When you use a smart phone or other mobile device to access our website or use the Mobile Apps, we may collect information about your physical location if (a) “location services” for the mobile application is enabled and/or (b) the permissions in the mobile device allow communication of this information. If you do not want us to collect your location information, you can opt out of sharing this information by changing the relevant preferences and permissions in your mobile device. </p>
                    <p> When you install our Mobile Apps or use a computer, tablet, smart phone or other device to access our websites or purchase products or services via our online platforms, we may collect information about the device and how you use it as well as information about the products you buy, including where you buy products, how frequently you buy products, and the rewards earned due to a purchase. See more information about how we collect this information below in section 4. </p>
                    <p><span>Information We Collect Elsewhere</span><br /> We also may collect information that is made available publicly, including information you submit to a blog, a chat room, or a social network like Facebook, Instagram, Twitter or Pinterest. Further, we may also collect information about you from other companies, organizations or persons. By gathering additional information about you, we can correct inaccurate information and give you product recommendations and special offers that are more likely to interest you. Should you have any questions on any of these points, do not hesitate to contact us with the information provided below. </p>
                    <p><span>How do we collect this information and how do we respond to Do Not Track signals?</span><br /> You may choose to voluntarily provide both general and personal information to the Company through our website (e.g., by contacting us through our website contact form). </p>
                    <p> We may also use cookies to gather information about your visits to our website and enhance your experience. A cookie is a small string of information that the website you visit transfers to your computer for identification purposes. Cookies can be used to follow your activity on the website and that information helps us to understand your preferences and improve your website experience. Cookies are also used for such activities as remembering your user name and password, if you use such a feature on this site. You can turn off all cookies, in case you prefer not to receive them. You can also have your computer warn you whenever cookies are being used. For both options you have to adjust your browser settings (such as Mozilla, Firefox, or Internet Explorer). There are also software products available that can manage cookies for you. Please be aware, however, that when you choose to reject cookies, this choice may limit the functionality of the website you visit and you may lose access to some of its features. For more information about cookies, including how to refuse cookies, consider reading the content available at www.allaboutcookies.org. </p>
                    <p> When you visit our website we may also collect “click stream data” (server address, domain name). This information can be combined with information you have provided to us by registering, for example, which will enable us to analyze and better customize your visits. We may use click stream data for traffic analysis or e-commerce analysis of our website, to determine which features of our site are most or least effective or useful to you. </p>
                    <p> There is currently no universal or even widely accepted standard for what constitutes “do not track” or the policies and procedures a website must have in place to sufficiently honor a “do not track” request that is received. Although we are share the California legislature’s goal of identifying ways that we can further protect our customer’s identities and help to maintain their autonomy, we are left to wait for clarification from the State of California before addressing the issue of “do not track” signals. If you would like to further educate yourself on “do not track” signals, one place to start is www.allaboutdnt.com. We are not responsible for the content of this external site, and we are not in any away affiliated with the site. </p>
                    <p><span>How do we use this information?</span><br /> We primarily use the most general of information we gather to keep our Site and our Mobile Apps at peak performance, and this includes sharing this information with our parent company, Shopsadiq Limited Inc. This also includes using the information to troubleshoot, resolve disputes or complaints, manage the administrative tasks related to the Company, the Site and the Mobile Apps, keep you updated and contact you, enforce agreements, comply with legal requirements, and cooperate with law enforcement. This information helps us identify areas of our website that we can improve on to further increase the usefulness of our site for our visitors. In addition to above, we may use the general, personal and sensitive information you provide to offer and provide products and services to you, including, but not limited to, the following: </p>
                    <p> To administer a content promotion survey or other site and to provide you with the relevant products or services (for example, to deliver a prize you may have won in a contest). </p>
                    <p> To send you information or materials via e-mail about products, offers, and news we think might interest you. This information may relate to products, offers, and news of Shopsadiq Limited Inc or its licensees or selected commercial partners. You can opt-out of this within your account settings or at the time your account is setup. </p>
                    <p> To protect Shopsadiq Limited Inc rights and/or property, as well as the rights and/or property of others, including Shopsadiq Limited Inc Inc.’s parent company. </p>
                    <p> To analyze visits to the website and learn about the interests of our visitors in aggregate and for internal operations, including troubleshooting, data analysis, testing, research and service improvement. Also on a personal level to better understand your interests and needs, so we can improve our products and services and deliver to you the type of content, features and promotions that you are most interested in. </p>
                    <p> To allow service providers so they can help us provide services (for which they may have access to your information), which is discussed in more detail below in section 6.<br /> We may also use the different types of information we collect about you in a variety of ways. For example: </p>
                    <p> disclose your information to third parties for their research, marketing and promotional purposes and those third parties may publicly report the findings of their research or analysis and/or organizations that conduct research into consumer spending;
                        <br />
                        to process your purchases of products and services;
                        <br />
                        to provide you with purchase recommendations;
                        <br />
                        to provide you with loyalty rewards or to communicate with you about promotional information; orders, purchases, services, accounts, programs, contests, and sweepstakes;
                        <br />
                        to respond to your customer service inquiries and requests for information;
                        <br />
                        to post your comments or statements on our Site;
                        <br />
                        to send you personalized promotions and special offers;
                        <br />
                        to maintain and improve our Site and our Mobile Apps and the products and services we offer;
                        <br />
                        to detect, prevent, or investigate security breaches or fraud; and
                        <br />
                        to maintain appropriate records for internal administrative purposes.
                        <br />
                        We may disclose your information to our Shopsadiq Limited Inc Merchants to help them better offer you deals, promotions and improve your overall customer experience. You can always view an updated list of our Shopsadiq Limited Inc Merchants here. We work together with our Shopsadiq Limited Inc Merchants to protect your privacy and the Shopsadiq Limited Inc Merchant agreement requires that the merchant agrees to maintain your information confidential.

                    </p>
                    <p>
                        disclose your information to third parties for their research, marketing and promotional purposes and those third parties may publicly report the findings of their research or analysis and/or organizations that conduct research into consumer spending;
                        <br />
                        to process your purchases of products and services;
                        <br />
                        to provide you with purchase recommendations;
                        <br />
                        to provide you with loyalty rewards or to communicate with you about promotional information; orders, purchases, services, accounts, programs, contests, and sweepstakes;
                        <br />
                        to respond to your customer service inquiries and requests for information;
                        <br />
                        to post your comments or statements on our Site;
                        <br />
                        to send you personalized promotions and special offers;
                        <br />
                        to maintain and improve our Site and our Mobile Apps and the products and services we offer;
                        <br />
                        to detect, prevent, or investigate security breaches or fraud; and
                        <br />
                        to maintain appropriate records for internal administrative purposes.
                        <br />
                        We may disclose your information to our Shopsadiq Limited Inc Merchants to help them better offer you deals, promotions and improve your overall customer experience. You can always view an updated list of our Shopsadiq Limited Inc Merchants here. We work together with our Shopsadiq Limited Inc Merchants to protect your privacy and the Shopsadiq Limited Inc Merchant agreement requires that the merchant agrees to maintain your information confidential.

                    </p>
                    <p><span>We may also disclose your information for the following reasons:</span>
                        <br />
                        to respond to subpoenas, court orders, or legal process;
                        <br />
                        in order to investigate, prevent, defend against, or take other action regarding violations of our Terms of Service, illegal activities, suspected fraud, or situations involving potential threats to the legal rights or physical safety of any person or the security of our network, Site or Mobile Apps;
                        <br />
                        to respond to claims that any posting or other content violates the rights of third parties;
                        <br />
                        in an emergency, to protect the health and safety of our Site’s users or the general public; or
                        <br />
                        as otherwise required by any applicable law.
                        <br />
                        Important information about third parties and website links
                        <br />
                        Shopsadiq Limited Inc websites and the personal information and general information collected are managed and controlled by Shopsadiq Limited Inc located at ADGM Al Khatem Tower Abu Dhabi, UAE. The servers that make Shopsadiq Limited Inc Site available and offer the Mobile Apps for download may be located all over the world. If you are located outside the United States, please note that the information you provide will be transferred to Shopsadiq Limited Inc and by submitting the information you authorize this transfer. Shopsadiq Limited Inc has entered into an agreement with its hosting company to provide safeguards for the protection of your data that are consistent with this policy.

                    </p>
                    <p> However, please keep in mind that Shopsadiq Limited Inc strategic partners may also have access to customer information. Shopsadiq Limited Inc does not control these third parties, but does work hard with these partners to make security of your information a priority. Some of these partners may not be located in the United States. </p>
                    <p> Other than to the companies belonging to Shopsadiq Limited Inc or its affiliates or parent company, for the purposes set out above, and except as described in this policy, we do not sell, trade, or otherwise transfer your personal information or general information to third parties without informing you in advance. We provide some of our services through contractual arrangements with affiliates, services providers, partners and other third parties (collectively, “service partners”). Our service partners may use your personal information to operate our sites and to deliver their services. For example, your data may be transferred to website hosting partners and other parties who assist us in designing and operating the website, executing services (such as for prize fulfillment), or helping us analyze collected data. These parties will only use your data for the purpose of carrying out the work as agreed with us and will be required to keep the information confidential. We will encourage our service partners to adopt and post privacy policies. However, the use of your personal information
                        <br />
                        by our service partners is governed by the privacy policies of those service partners and is not subject to our control.
                    </p>
                    <p> For your convenience and to improve the usage of the website we may insert links to third parties on this site. This privacy policy does not apply to such third party websites. These links take you outside our service and off the Shopsadiq Limited Inc site and are beyond our control. This includes links from partners that may use Shopsadiq Limited Inc logos as part of a co-branding agreement. The sites those links can take you to have their own separate privacy policy and although we seek to protect the integrity of our site, Shopsadiq Limited Inc is not liable for the content and activities of those sites. Your visits and access to such websites is at your own risk. Please note that those other sites may send their own cookies to users, collect data, or solicit personal information.
                        <br />
                        imminent bodily harm. We also may retain information from accounts disabled for violations of our terms for at least a year to prevent repeat abuse or other violations of our terms.
                    </p>
                    <p> As is the nature of business today, our Company may be acquired by or merged with another company or go through another corporate event or change. Further, there is always the possibility that a company will have to file for protection under bankruptcy laws. In the event of any of these scenarios, any of your personal, general or sensitive information will transfer to the new entity. Information you provide after the event or change may be governed by another privacy policy. </p>
                    <p><span>How do we protect your information?</span>
                        <br />
                        Security Measures.In order to protect both the personal information and the general information that we receive from you through our website, we have implemented various security measures, including the request to authenticate your identity using your social sign in and authenticating your password, creating a PIN for your mobile device, validating your email address prior to authorizing and releasing prizes/rewards card.
                    </p>
                    <p><span>Risk of Interceptions. </span>
                        Please keep in mind, however, that whenever you give out personal information online there is a risk that third parties may intercept and use that information. While Shopsadiq Limited Inc strives to protect your personal information and privacy, we cannot guarantee the security of any information you disclose online. By using this site, you expressly acknowledge and agree that we do not guarantee the security of any data provided to or received by us through this site and that any personal information, general information, or other data or information received from you through the site is provided to us at your own risk, which you expressly assume.
                        <br />
                        What can you do about deleting or modifying your data?
                        <br />
                        You may opt out of receiving any messages from Shopsadiq Limited Inc. Additionally, you can choose to have all your personal information deleted from our database. You may opt out, or delete or modify your personal information, by sending us a request by email at support (at) theDroplinked.com. We encourage you promptly to contact us with your updated your personal information when it changes.
                    </p>
                    <p><span>How old do you have to be?</span>
                        <br />
                        Shopsadiq Limited Inc intends only persons who are 13 years or older to use this website. Shopsadiq Limited Inc is committed to protecting the privacy of children and has no intention of collecting personal data from children under the age of 13. We encourage parents and guardians of children under 18 to regularly check and monitor their children’s use of e-mail and other activities online. We do not knowingly collect personal information from anyone under the age of 13. If we learn or have reason to suspect that a site user is under age 13, we will promptly delete any personal information in that user’s account.
                    </p>
                    <p><span>Retention and Legal Demands.</span>
                        <br />
                        We may access, preserve and share your information in response to a legal request (like a search warrant, court order or subpoena) if we have a good faith belief that the law requires us to do so. This may include responding to legal requests from jurisdictions outside of the United States where we have a good faith belief that the response is required by law in that jurisdiction, affects users in that jurisdiction, and is consistent with internationally recognized standards. We may also access, preserve and share information when we have a good faith belief it is necessary to: detect, prevent and address fraud and other illegal activity; to protect ourselves, you and others, including as part of investigations; or to prevent death or imminent bodily harm. We also may retain information from accounts disabled for violations of our terms for at least a year to prevent repeat abuse or other violations of our terms.
                    </p>
                    <p><span>Security Best Practices – How Can You Help to Stay Safe</span>
                        <br />
                        We take your security seriously, and you should too. Technology gives us freedoms today that were never dreamed about less than a decade ago. But with this freedom comes great responsibility. We highly recommend that you adopt healthy security habits. Some of the habits you may want to adopt include:
                    </p>
                    <p> Protect your username and password. Do not share this information.
                        <br />
                        We may issue you a physical Shopsadiq Limited Inc Card. Protect this as you would a credit card and keep it safe! Please report to us if your card is lost or stolen.
                        <br />
                        If you ever receive an email related to our Company that you think may not be legitimate, please let us know as soon as possible. We can be reached at support (at) theDroplinked.com. We will never ask you in an email to provide your password.
                        <br />
                        Change your password at least a few times per year.
                        <br />
                        Do not reuse passwords and make sure that you use a different password for each online account.
                        <br />
                        Consider a password that is easy for you to remember but difficult for people to guess.
                        <br />
                        Be skeptical of public WiFi. Consider using higher security settings when in public, and try to limit the sensitive data you send over public networks.
                        <br />
                        Always make sure that when you are entering sensitive information into a website (like when banking or shopping) ensure that the website is secure by making sure the URL starts with https and not just http.
                        <br />
                        A Global Reality. Locality, Choice of Law and California Privacy Notice.
                        <br />
                        Although both our Site and Company is based in Delaware, we recognize that not all of our users will be. If you hail from outside of the United States, please be aware that your local rules and regulations regarding privacy may differ (and likely do) from those we have in Delaware. By providing us with your information and using our Site and/or the Mobile Apps, you consent to having your information sent to the United States for processing, storage and maintenance. You are also consenting to the application of California laws and applicable U.S. Federal laws to all matters related to this privacy policy, our terms of use and our mobile applications agreement.

                    </p>
                    <p> As for California law (specifically the California Online Privacy Protection Act (the “Act”) and the California Business and Professions Code), we now want to briefly summarize your privacy rights under the Act. The Act requires that we provide you with the categories of personally identifiable information that we collect through the Site and the categories of third party persons or entities with whom such personally identifiable information may be shared for direct marketing purposes at your request. If you are a California resident, you can request a notice disclosing these categories of personal information we have shared with third parties for the third parties’ direct marketing purposes. To request a notice, please submit your request by postal mail labeled “California Customer Choice Notice” to: Shopsadiq Limited Inc. ATTN: Legal, ADGM Al Khatem Tower Abu Dhabi, UAE. We will make every effort to promptly respond to your request, but please allow at least thirty (30) calendar days for a response. Also, please note that there is no charge for controlling the sharing of your personally identifiable information or requesting this notice. </p>
                    <p><span>How to Contact Us.</span>
                        <br />
                        If you have any questions or comments relating to our website or the privacy policy, please send an email to support (at) theDroplinked.com with the subject line “Website” or “Privacy Policy” or write to us at the address listed below.
                    </p>
                    <p> We also welcome questions, comments, complaints, website feedback and autographed LPs. Please use the following information to reach out to us.
                        <br />
                        Post: Shopsadiq Limited Inc Inc., ADGM Al Khatem Tower Abu Dhabi, UAE
                        <br />
                        Email: support (at) theDroplinked.com
                    </p>
                    <Link to="/terms">
                        <button className="terms-btn"><p>TERMS OF SERVICE</p></button>
                    </Link>
                </div>
            </div>
        </div>
    )
}

