namespace miniChartsFlagsModel {
    export const colors = {
        green: '#2BCFA1',
        purple: '#9C4EFF',
        yellow: '#FEB900',
        gold: 'rgba(254, 185, 0, 0.50)',
        brown: 'rgba(254, 185, 0, 0.20)',
    }
}

export default miniChartsFlagsModel