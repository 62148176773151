import { MODAL_TYPE } from "pages/public-pages/homePage/HomePage";
import useSubscriptionPlanPurchaseStore from "pages/subscription-plans/_components/plans/store/planPurchaseStore";
import React, { useState } from "react";
import CompleteGoogelModal from "../complete-google/CompleteGoogelModal";
import LoginModal from "../login-modal/LoginModal";
import ResetPassModal from "../reset-pass-modal/ResetPassModal";
import SignupModal from "../signup-modal/SignupModal";

interface Props {
  show: boolean;
  close: () => void;
  type?: MODAL_TYPE;
  isFromPlansPage?: boolean;
  openPlanPurchaseModal?: () => void
}

const AuthModal = ({ show, close, type = MODAL_TYPE.SIGNIN, isFromPlansPage, openPlanPurchaseModal }: Props) => {
  const [modalType, setModalType] = useState(type ? type : MODAL_TYPE.SIGNIN);
  const selectedPlan = useSubscriptionPlanPurchaseStore((state) => state.selectedPlan)

  const switchModal = () => setModalType(modalType === MODAL_TYPE.SIGNIN ? MODAL_TYPE.SIGNUP : MODAL_TYPE.SIGNIN);
  const switchModalWithType = (type: MODAL_TYPE) => setModalType(type)
  const switchResetModal = () => setModalType(modalType === MODAL_TYPE.SIGNIN ? MODAL_TYPE.RESET : MODAL_TYPE.SIGNIN);
  const closeModal = () => {
    setModalType(type ? type : MODAL_TYPE.SIGNIN)
    close()
  }

  const renderContent = () => {
    switch (modalType) {
      case MODAL_TYPE.SIGNIN: return <LoginModal show={show} close={closeModal} switchModal={switchModal} switchReset={switchResetModal} isFromPlansPage={isFromPlansPage} />
      case MODAL_TYPE.SIGNUP: return <SignupModal show={show} close={closeModal} switchModal={switchModal} isFromPlansPage={isFromPlansPage} subscriptionPlan={selectedPlan} openPlanPurchaseModal={openPlanPurchaseModal} />
      case MODAL_TYPE.RESET: return <ResetPassModal show={show} close={closeModal} switchReset={switchResetModal} />
      case MODAL_TYPE.GOOGLE: return <CompleteGoogelModal show={show} close={closeModal} switchModal={switchModalWithType} isFromPlansPage={isFromPlansPage} subscriptionPlan={selectedPlan} />
    }
  }

  return (
    <>
      {renderContent()}
    </>
  )
}

export default AuthModal