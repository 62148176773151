const productErrors = {
    sku_not_added: "SKU is required",
    product_image_required: "Please upload the product image to proceed",
    mockup_image_required: "Please upload the mockup image to proceed",
    product_description_too_long: "Please limit your description to 250 characters",
    product_image_type_not_supported: "Please upload an image in JPG, PNG, or GIF format.",
    there_is_the_same_sku: "You already added a variant with this SKU",
    your_product_published: "The product has been published successfully!",
    your_product_updated: "The product has been updated successfully!",
    your_product_draft: "The product has been drafted successfully!",
    variant_has_been_deleted: "Variant has been deleted",
    artwork_should_be_provided: "Artwork should be provided to publish product",
    artwork_uploaded_successfully: "Artwork has been uploaded successfully!",
}

export default productErrors