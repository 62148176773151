import AppIcons from "assest/icon/Appicons";
import React from "react";

export const OPTIONS = [
    { icon: <AppIcons.Dashboard />, label: 'Analytics', path: '' },
    { icon: <AppIcons.ProductsIcon />, label: 'Products', path: '/products' },
    { icon: <AppIcons.CollectionIcon />, label: 'Collections', path: '/collections' },
    { icon: <AppIcons.OrderIcon />, label: 'Orders', path: '/orders' },
    { icon: <AppIcons.InformationIcon />, label: 'Affiliate', path: '/affiliate' },
    { icon: <AppIcons.NFT />, label: 'NFTs', path: '/nfts' },
    { icon: <AppIcons.Blog />, label: 'Blogs', path: '/blogs' },
    { icon: <AppIcons.SubscriptionPlan />, label: 'Pricing Plans', path: '/plans' },
    { icon: <AppIcons.Gamification />, label: 'Rewards Panel', path: '/gamification' },
    { icon: <AppIcons.SettingIcon />, label: 'Settings', path: '/settings/shop-info' },
]